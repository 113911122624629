import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationStart, GuardsCheckStart, NavigationEnd} from '@angular/router';
import {RouterService} from '../../services/navigation/router.service';
import {SeitenleisteService} from '../../submodul/obs.seitenleiste/seitenleiste.service';
import {Subscription} from 'rxjs';
import {FlexLinkService} from '../../services/navigation/flexLink.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy{
  public navigation = true
  public seitenleiste = true
  public seitenleisteSubscription: Subscription

  constructor(
    private router_service: RouterService,
    private seitenleistenService: SeitenleisteService,
    public router: Router,
    private flexLink: FlexLinkService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart){
        this.router_service.mitteilungWbpAus()
      }
      if (event instanceof NavigationStart) {
        this.router_service.navCounter()
      }
      if (event instanceof NavigationEnd){
        this.router_service.revisionsKontrolle(this.router.url.split('/')[1])
        this.router_service.regLoeschen()
        this.router_service.navController()
        this.flexLink.flexLinkSpeichern()
        if (event.url.match('reset_password') || event.url.match('verify_email')){
          this.navigation = false
        }else{
          this.navigation = true
        }
      }
    })
  }

  ngOnInit() {
    this.seitenleisteSubscription = this.seitenleistenService.zeigeSeitenleiste.subscribe(
      seitenleiste => this.seitenleiste = seitenleiste
    )
  }

  ngOnDestroy() {
    this.seitenleisteSubscription.unsubscribe()
  }
}
