import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { AppStateService } from 'services/app/app-state.service';
import {Router} from '@angular/router';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {RegistrierungService} from '../../../../services/registrierung/registrierung.service';
import {Platform} from '@angular/cdk/platform';
import {PasswortVergessenComponent} from '../passwort-vergessen/passwort-vergessen.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public hide: boolean = true
  public mandant
  public methode
  public login_form: UntypedFormGroup
  public detectedPlattform: string
  public error = false
  @ViewChild('login') login: ElementRef;
  @ViewChild('password') password: ElementRef;
  @Output() loginEvent = new EventEmitter()
  @Output() buttonClickedEvent = new EventEmitter<void>();

  constructor(
    private app_state_service: AppStateService,
    private router: Router,
    public platform: Platform,
    private kunde_service: KundeService,
    private mandant_service: MandantenService,
    public registrationService: RegistrierungService,
    private matDialog: MatDialog,
  ) {
    this.app_state_service.set_page_name('Anmelden');
    this.app_state_service.set_page_navigation('profils');
  }

  ngOnInit() {
    if (this.platform.ANDROID) {
      this.detectedPlattform = 'ANDROID'
    }
    else if (this.platform.IOS) {
      this.detectedPlattform = 'IOS'
    }
    else {
      this.detectedPlattform = 'WEB'
    }
    this.mandant_service.mandant_get().subscribe((mandant) => {
      this.mandant = mandant
      if (this.mandant.darstellungRegistrierung != 3) {
        let vertrag = Object.keys(mandant.registrierFormen)
        if (vertrag.length > 0){
          this.methode = mandant.registrierFormen[vertrag[0]].registrierArt
        }
      }
    })
    this.login_form = new UntypedFormGroup({
      'login': new UntypedFormControl('', Validators.required),
      'password': new UntypedFormControl('', Validators.required)
    });
  }

  buttonClicked() {
    this.buttonClickedEvent.emit();
  }

  public on_change_login(): void {
    this.login_form.get('login').setValue(this.login.nativeElement.value)
  }

  public on_change_pw(): void {
    this.login_form.get('password').setValue(this.password.nativeElement.value)
  }

  onSubmit(): void {
    const credentials = this.login_form.value;
    this.loginEvent.emit(this.kunde_service.kunde_login({
      user: credentials.login,
      password: credentials.password
    }))
  }

  public link_pw_forget() {
    if (this.detectedPlattform == 'WEB'){
      this.matDialog.open(PasswortVergessenComponent);
    }
    else {
      this.router.navigateByUrl('passwort-vergessen')
    }
  }

  public show_pw(element) {
    if (element.type === 'password') {
      element.type = 'text';
    } else {
      element.type = 'password';
    }
  }

}
