<mat-card class="recht" *ngIf="rechts_content && rechts_txt" [class.faq-content]="name == 'faq'">
  <div id="recht-content" [class.recht-content-kontakt]="name == 'kontakt'" [class.faq-content]="name == 'faq'">
    <div *ngIf="name != 'kontakt' && name != 'kuendigung' && name != 'faq'" class="recht_text" >
      <div class="recht_text_inhalt contentblock" [innerHTML]="content"></div>
    </div>
    <kontakt *ngIf="name == 'kontakt'" class="recht_text" ></kontakt>
    <vertrag-kuendigen *ngIf="name == 'kuendigung'"></vertrag-kuendigen>
    <faq *ngIf="name == 'faq'"></faq>
  </div>
</mat-card>
