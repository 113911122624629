<div id="footer">
  <div id="footer-menu">
    <a
      *ngIf="rechts_content && flexLink.flexLinkTesten('agb')"
      mat-menu-item
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'agb'"
      (click)="flexLink.flexLinkPruefen('agb')"
    >
      <span>AGB</span>
    </a>
    <a
      *ngIf="rechts_content && flexLink.flexLinkTesten('datenschutz')"
      mat-menu-item
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'datenschutz'"
      (click)="flexLink.flexLinkPruefen('datenschutz')">
      <span>Datenschutz</span>
    </a>
    <a
      *ngIf="rechts_content && flexLink.flexLinkTesten('widerruf')"
      mat-menu-item
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'widerruf'"
      (click)="flexLink.flexLinkPruefen('widerruf')"
    >
      <span>Widerruf</span>
    </a>
    <a
      *ngIf="rechts_content && flexLink.flexLinkTesten('impressum')"
      mat-menu-item
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'Impressum'"
      (click)="flexLink.flexLinkPruefen('impressum')">
      <span>Impressum</span>
    </a>
    <a
      *ngIf="flexLink.flexLinkTesten('kontakt')"
      mat-menu-item class="footerMenuPunkt"
      [class.aktiv]="seite == 'kontakt'"
      (click)="flexLink.flexLinkPruefen('kontakt')"
    >
    <span>Kontakt</span>
  </a>
    <a
      *ngIf="kuendigung != '' && flexLink.flexLinkTesten('kuendigung')"
      mat-menu-item
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'kuendigung'"
      (click)="flexLink.flexLinkPruefen('kuendigung')"
    >
      <span>Kündigung</span>
    </a>
    <a
      *ngIf="faq && flexLink.flexLinkTesten('faq')"
      class="footerMenuPunkt"
      [class.aktiv]="seite == 'faq'"
      (click)="flexLink.flexLinkPruefen('faq')"
    >
      <span>FAQ</span>
    </a>
  </div>
  <span id="footer-info" gitVersion>Version #33286</span>
</div>
