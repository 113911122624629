<div id="menuVorauswahlKarte" *ngIf="menuInformationen" (click)="menuVorauswahlDetails($event)" >
  <div id="menuVorauswahlDetails">
    <strong>{{ menuInformationen.bezeichnung }}</strong>
    <div id="menuVorauswahlDetailsText">
      <span [innerHTML]="menuInformationen.menueText | bbCode"></span>
      <div *ngFor="let menuKomponenten of menu.optionen | keyvalue">
        <ng-container
          *ngIf="menuKomponenten.value['pos'] != '1' && tag.vorausgewaehlt.menues[menu.menueNr][menuKomponenten.value['pos']] != '0'"
        >
          <span [innerHTML]="tag.vorausgewaehlt.menues[menu.menueNr][menuKomponenten.value['pos']] | menuVorauswahlMenutext:tag | bbCode"></span>
        </ng-container>
      </div>
    </div>
  </div>
  <div id="menuVorauswahlMenuToolsContainer" *ngIf="tag.datum" [class.gesperrt]="gesperrt">
    <div class="menuToolsBtn" (click)="menuVorauswahlLoeschen()">
      <mat-icon id="loeschen">delete</mat-icon>
    </div>
    <div class="menuToolsBtn" (click)="menuVorauswahlEditor()">
      <mat-icon id="bearbeiten">edit</mat-icon>
    </div>
  </div>
</div>
