<div id="vorauswahlMenuContainer" (click)="aktivesMenue($event)">
  <div
    *ngIf="tag.vorausgewaehlt.menues[menu.menueNr]['1'] == '0'"
    id="menuKarteLeer"
    (click)="menuVorauswahlEditor()"
    [class.gesperrt]="gesperrt"
  >
    <mat-icon>add</mat-icon>
  </div>
  <div *ngIf="tag.vorausgewaehlt.menues[menu.menueNr]['1'] != '0'" id="menuKarteGefuellt">
    <menuVorauswahlMenukarte
      id="menuKarte"
      [tag]="tag"
      [menu]="menu"
      [mandant]="mandant"
    ></menuVorauswahlMenukarte>
  </div>
</div>
