import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuVorauswahlMenutext'
})
export class MenuVorauswahlMenutextPipe implements PipeTransform {

  transform(menuId: string, tag): string | boolean {
    if (tag.tagesMenues[menuId].menueText !== ''){
      return tag.tagesMenues[menuId].menueText
    }
    return ''
  }

}
