<div id="vorauswahlTagLabel">
  <strong>{{ tag.datum | date: 'dd.MM.YY' }} - {{ tag.datum | datum:'wochentag' }}</strong>
</div>
<div id="vorauswahlTagContainer">
  <div id="vorauswahlTagContent" [ngClass]="gridKlasse">
    <div *ngFor="let menu of tag.schablonenMenues | keyvalue" class="vorauswahlTagKarte">
      <menuVorauswahlMenu
        [mandant]="mandant"
        [menu]="menu.value"
        [tag]="tag">
      </menuVorauswahlMenu>
    </div>
  </div>
</div>
