import { AfterViewInit, Component, OnInit } from '@angular/core';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {FaqService} from '../../../obs.shared/componenten/faq/faq.service';
import {FlexLinkService} from '../../../../services/navigation/flexLink.service';


@Component({
	selector: 'rechtliches-seitenleiste',
	templateUrl: './rechtliches.seitenleiste.component.html',
	styleUrls: ['./rechtliches.seitenleiste.component.css'],
})
export class RechtlichesSeitenleisteComponent implements OnInit, AfterViewInit {

  public mandant
  public aktuelleUrl
  public kuendigung
  public kunde
  public faq

	constructor(
    private kunden_service: KundeService,
    public faq_service: FaqService,
    public flexLink: FlexLinkService,
	) { }

	ngOnInit() {
    this.kuendigung = this.mandant.sonderKonfiguration.API_1_5.terminateContractURL
    this.kunden_service._kunde_get().subscribe((kunde) => {
      this.kunde = kunde
      this.faq_service.faqAnzeigen(kunde)
      this.faq_service.faqAnzeige.subscribe(anzeige => {
        this.faq = anzeige
      })
    })
	}

	ngAfterViewInit(){}
}
