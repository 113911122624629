import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'menuVorauswahlTag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})

export class TagComponent implements OnInit {

  public gridKlasse

  @Input('mandant') mandant
  @Input('tag') tag

  constructor() {
  }

  ngOnInit() {
    let erstesMenue = Object.values(this.tag.schablonenMenues)[0]
    if (Object.keys(erstesMenue['optionen']).length > 1) {
      this.gridKlasse = 'komponenten'
    }
  }


}
