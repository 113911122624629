import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuVorauswahlMenuname'
})
export class MenuVorauswahlMenunamePipe implements PipeTransform {

  transform(menuId: string, tag): string | boolean {
    if (tag.tagesMenues[menuId].bezeichnung !== ''){
      if (menuId.includes('M')){
        return tag.tagesMenues[menuId.replace('M', '')].bezeichnung
      }
      return tag.tagesMenues[menuId].bezeichnung
    }
    return false
  }

}
