import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MenuVorauswahlService} from './services/menuVorauswahl.service';
import {SpeiseplanService} from '../obs.speiseplan/services/speiseplan.service';

@Component({
  selector: 'menuVorauswahl',
  templateUrl: './menuVorauswahl.component.html',
  styleUrls: ['./menuVorauswahl.component.css'],
})

export class MenuVorauswahlComponent implements OnInit, AfterViewInit {

  public mandant
  public kunde
  public apiZeitraum
  public apiMenuVorauswahlContent

  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
    private speiseplanService: SpeiseplanService,
  ) {
  }

  public subscription: Subscription

  ngOnInit() {
    this.menuVorauswahlService.menuVorauswahlInit().subscribe(apiResponse => {
      this.mandant = apiResponse[0];
      this.kunde = apiResponse[1];
      this.speiseplanService.speiseplan_zeitraum().subscribe(apiZeitraum => {
        this.apiZeitraum = apiZeitraum;
      })
    })
    this.subscription = this.menuVorauswahlService.menuVorauswahl.subscribe((response:any) => {
      this.apiMenuVorauswahlContent = response;
    })
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
