import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';

@Component({
  selector: 'menuVorauswahlMenu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})

export class MenuComponent implements OnInit {

  @Input('mandant') mandant
  @Input('menu') menu
  @Input('tag') tag
  public gesperrt:boolean = false

  constructor(
    private menuVorauswahlService: MenuVorauswahlService,
  ) {
  }

  ngOnInit() {
    if (Math.floor(new Date(this.tag.datum).getTime() / 1000) < Math.floor(new Date().setHours(0,0,0,0) / 1000)){
      this.gesperrt = true
    }
    if (this.tag.vorauswahlSchluss <= Date.now() / 1000){
      this.gesperrt = true
    }
  }

  public menuVorauswahlEditor(){
    this.menuVorauswahlService.menuVorauswahlEditor(this.menu, this.tag, this.mandant)
  }

  public aktivesMenue(menu) {
    const target = menu.target as HTMLElement;
    let aktivesmenu:any = document.querySelectorAll('.aktiv')
    if (aktivesmenu){
      aktivesmenu.forEach(div => {
        div.classList.remove('aktiv')
      })
    }
    if (target.id != 'loeschen'){
      const parentDiv:any = target.closest('div[id="vorauswahlMenuContainer"]');
      parentDiv.classList.add('aktiv')
    }
  }
}
