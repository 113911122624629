import {Component, OnInit, ViewChild} from '@angular/core';
import { KundeService } from 'services/kunden/kunde.service';
import { ProfilComponent } from '../../../submodul/obs.shared/componenten/profil/profil.component';
import profil_config from 'configs/profil.config.json';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {ProfilSeitenleisteComponent} from '../../../submodul/obs.seitenleiste/components/profil/profil.seitenleiste.component';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {BehaviorSubject, Subscription, zip} from 'rxjs';
import {MandantenService} from 'services/mandanten/mandanten.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'web-profil',
  templateUrl: './web-profil.component.html',
  styleUrls: ['./web-profil.component.css'],
})
export class WebProfilComponent implements OnInit {

  @ViewChild('profil') profil_funktionen:ProfilComponent
  public form
  public mandant
  public kunde
  public aktiv = false
  public profil = profil_config
  public config = [
    'neues_passwort',
    'neues_passwort_wiederholt'
  ]
  public error = []
  public emitterSubscription: Subscription
  public seite

  constructor(
    private mandantenService: MandantenService,
    private kunde_service: KundeService,
    private seitenleistenService: SeitenleisteService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.init()
    this.seitenleistenService.emitterSeitenleiste = new BehaviorSubject<any>(null)
    this.emitterSubscription = this.seitenleistenService.emitterSeitenleiste.subscribe(event => {
      this.seitenLeistenTrigger(event)
    })
  }

  public init() {
    this.route.queryParams.subscribe(url => {
      zip(
        this.mandantenService.mandant_get(),
        this.kunde_service.kunde_get()
      ).subscribe((response) => {
        this.mandant = response[0]
        this.kunde = response[1]
        this.seite = url.id
        this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
          ProfilSeitenleisteComponent, {
            mandant: this.mandant,
            kunde: this.kunde,
            aktuelleUrl: url.id
          })
        )
      })
    })


  }

  change(event) {
    this.profil_funktionen.change(event)
  }

  onSubmit() {
    this.profil_funktionen.onSubmit()
  }

  public seitenLeistenTrigger(event){
    if (event){
      if (event.length > 1){
        this.change(event[1])
      }
      else {
        this[event]()
      }
    }
  }

  ngOnDestroy() {
    this.emitterSubscription.unsubscribe()
  }

  protected readonly environment = environment;
}
