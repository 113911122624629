import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../../directives/directives.module'
import { PipeModule } from '../../pipe/pipe.module';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.components';
import { ConfirmRoutingDialogComponent } from './dialog/confirm-routing-dialog/confirm-routing-dialog.components';
import {MaterialModule} from '../../application/web/material.module';
import { SnackbarComponent } from './fragmente/snackbar/snackbar.component';
import { FormularTextInputComponent } from './fragmente/formular-felder/formular-text-input/formular-text-input.component';
import { FormularSelectInputComponent } from './fragmente/formular-felder/formular-select-input/formular-select-input.component';
import { FormularCheckboxInputComponent } from './fragmente/formular-felder/formular-checkbox-input/formular-checkbox-input.component';
import { FormularFreitextInputComponent } from './fragmente/formular-felder/formular-freitext-input/formular-freitext-input.component';
import { FormularDateInputComponent } from './fragmente/formular-felder/formular-date-input/formular-date-input.component';
import { RechtstexteComponent } from './fragmente/rechtstexte/rechtstexte.component';
import { FormularPasswordInputComponent } from './fragmente/formular-felder/formular-password-input/formular-password-input.component';
import {WartungsabgleichComponent} from './fragmente/wartungsabgleich/wartungsabgleich.component';
import {VerifyDialogComponent} from './dialog/verify-dialog/verify-dialog.components';
import {PasswortVergessenComponent} from './componenten/passwort-vergessen/passwort-vergessen.component';
import {KontaktComponent} from './componenten/kontakt/kontakt.component';
import {LoginComponent} from './componenten/login/login.component';
import {BilderKarussellComponent} from './fragmente/bilder-karussell/bilder-karussell.component';
import {VideoDialogComponent} from './dialog/video-dialog/video-dialog.components';
import {VertragKuendigenComponent} from './componenten/vertrag-kuendigen/vertrag-kuendigen.component';
import {MitteilungenDialogComponent} from './componenten/mitteilungen/mitteilungen-dialog/mitteilungen-dialog.component';
import {MitteilungenIconComponent} from './componenten/mitteilungen/mitteilungen-icon/mitteilungen-icon.component';
import {ProfilComponent} from './componenten/profil/profil.component';
import {EmailVerifikationComponent} from './componenten/email-verifikation/email-verifikation.component';
import {EinstellungenComponent} from './componenten/einstellungen/einstellungen.component';
import {StandartDialogComponent} from './dialog/standart-dialog/standart-dialog.components';
import {SpeiseplanPdfComponent} from './fragmente/pdf/speiseplan-pdf.component';
import {GesetzDialogComponent} from './dialog/gesetz-dialog/gesetz-dialog.component';
import {FehlerDialogComponent} from './dialog/fehlerDialog/fehlerDialog.component';
import {KalenderDialogComponent} from './dialog/kalender-dialog/kalender-dialog.component';
import {KalenderPickerComponent} from './fragmente/kalender-picker/kalender-picker.component';
import {WerbebannerComponent} from './componenten/werbebanner/werbebanner.component';
import {WerbebannerService} from './componenten/werbebanner/werbebanner.service';
import {WerbePopupComponent} from './componenten/werbePopup/werbePopup.component';
import {EinrichtungsSelektionComponent} from './fragmente/formular-felder/einrichtungsSelektion/einrichtungsSelektion.component';
import {SeitenleisteService} from '../obs.seitenleiste/seitenleiste.service';
import {FaqComponent} from './componenten/faq/faq.component';
import {FaqService} from './componenten/faq/faq.service';
import {LeistungsbeginnBundeslandComponent} from './fragmente/formular-felder/leistungsbeginnBundesland/leistungsbeginnBundesland.component';
import {RechnungenComponent} from './componenten/rechnungen/rechnungen.component';
import {RechnungenAnfordernComponent} from './componenten/rechnungen/rechnungenAnfordern/rechnungenAnfordern.component';
import {HomeComponent} from './componenten/home/home.component';
import {PwaInstallComponent} from './componenten/pwa-install/pwa-install.component';
import {SpeiseplanGlossarComponent} from '../obs.speiseplan/einzelbesteller/speiseplanGlossar/speiseplanGlossar.component';
import {ExpressStornoDialogComponent} from './componenten/expressStorno/expressStorno-dialog/expressStorno-dialog.component';
import {ExpressStornoIconComponent} from './componenten/expressStorno/expressStorno-icon/expressStorno-icon.component';
import {ZeitraumPickerComponent} from './componenten/zeitraumPicker/zeitraumPicker.component';
import {MitteilungenService} from './componenten/mitteilungen/mitteilungen.service';
import {LeistungsbeginnDatumComponent} from './fragmente/formular-felder/leistungsbeginnDatum/leistungsbeginnDatum.component';
import {PasswortRegelnService} from './fragmente/formular-felder/formular-password-input/passwortRegeln.service';
import {KalenderHeaderComponent} from './fragmente/kalenderHeader/kalenderHeader.component';
import {BarcodeKundennummerComponent} from './componenten/barcodeKundennummer/barcodeKundennummer.component';
import {CheckboxBestellerinnerungComponent} from '../obs.registrierung/sopro/checkboxBestellerinnerung/checkboxBestellerinnerung.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ConfirmRoutingDialogComponent,
    SnackbarComponent,
    FormularTextInputComponent,
    FormularSelectInputComponent,
    FormularCheckboxInputComponent,
    FormularFreitextInputComponent,
    FormularDateInputComponent,
    RechtstexteComponent,
    FormularPasswordInputComponent,
    WartungsabgleichComponent,
    VerifyDialogComponent,
    PasswortVergessenComponent,
    KontaktComponent,
    LoginComponent,
    BilderKarussellComponent,
    VideoDialogComponent,
    VertragKuendigenComponent,
    MitteilungenIconComponent,
    MitteilungenDialogComponent,
    ProfilComponent,
    EmailVerifikationComponent,
    EinstellungenComponent,
    StandartDialogComponent,
    SpeiseplanPdfComponent,
    GesetzDialogComponent,
    FehlerDialogComponent,
    KalenderDialogComponent,
    KalenderPickerComponent,
    WerbebannerComponent,
    WerbePopupComponent,
    FaqComponent,
    EinrichtungsSelektionComponent,
    LeistungsbeginnBundeslandComponent,
    RechnungenComponent,
    RechnungenAnfordernComponent,
    HomeComponent,
    PwaInstallComponent,
    SpeiseplanGlossarComponent,
    ExpressStornoDialogComponent,
    ExpressStornoIconComponent,
    ZeitraumPickerComponent,
    LeistungsbeginnDatumComponent,
    KalenderHeaderComponent,
    BarcodeKundennummerComponent,
    CheckboxBestellerinnerungComponent,
  ],
  exports: [
    ConfirmDialogComponent,
    ConfirmRoutingDialogComponent,
    FormularTextInputComponent,
    EinrichtungsSelektionComponent,
    RechtstexteComponent,
    WartungsabgleichComponent,
    VerifyDialogComponent,
    PasswortVergessenComponent,
    KontaktComponent,
    LoginComponent,
    VideoDialogComponent,
    VertragKuendigenComponent,
    MitteilungenIconComponent,
    MitteilungenDialogComponent,
    ProfilComponent,
    EmailVerifikationComponent,
    EinstellungenComponent,
    StandartDialogComponent,
    SpeiseplanPdfComponent,
    GesetzDialogComponent,
    FehlerDialogComponent,
    KalenderDialogComponent,
    KalenderPickerComponent,
    WerbebannerComponent,
    WerbePopupComponent,
    FaqComponent,
    LeistungsbeginnBundeslandComponent,
    RechnungenComponent,
    RechnungenAnfordernComponent,
    HomeComponent,
    PwaInstallComponent,
    SpeiseplanGlossarComponent,
    ExpressStornoDialogComponent,
    ExpressStornoIconComponent,
    ZeitraumPickerComponent,
    LeistungsbeginnDatumComponent,
    KalenderHeaderComponent,
    BarcodeKundennummerComponent,
    CheckboxBestellerinnerungComponent,
  ],
  providers: [
    WerbebannerService,
    MitteilungenService,
    SeitenleisteService,
    FaqService,
    PasswortRegelnService,
  ]
})
export class SharedModule { }
