import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Platform} from '@angular/cdk/platform';
import {environment} from 'environments/environment';
import {MenuVorauswahlService} from '../../services/menuVorauswahl.service';
import {ConfirmDialogComponent} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';

@Component({
  selector: 'menuVorauswahlMenukarte',
  templateUrl: './menuVorauswahlMenukarte.component.html',
  styleUrls: ['./menuVorauswahlMenukarte.component.css'],
})
export class MenuVorauswahlMenukarteComponent implements OnInit {

  @Input('mandant') mandant
  @Input('tag') tag
  @Input('menu') menu
  public menuInformationen
  public hauptmenuIndex
  public buffer
  public aktuelleMenuVorauswahl
  public gesperrt

  constructor(
    public dialog: MatDialog,
    public platform: Platform,
    public menuVorauswahlService: MenuVorauswahlService,
  ) {}

  ngOnInit() {
    this.hauptmenuIndex = this.tag.vorausgewaehlt.menues[this.menu.menueNr]['1']
    this.menuInformationen = this.tag.tagesMenues[this.hauptmenuIndex]
    this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
    this.buffer = { ...this.aktuelleMenuVorauswahl[this.tag.datum].vorausgewaehlt}
    if (Math.floor(new Date(this.tag.datum).getTime() / 1000) < Math.floor(new Date().setHours(0,0,0,0) / 1000)){
      this.gesperrt = true
    }
    if (this.tag.vorauswahlSchluss <= Date.now() / 1000){
      this.gesperrt = true
    }
  }

  public menuVorauswahlDetails(event) {
    this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(this.mandant, this.menuInformationen, this.buffer, this.menu, this.hauptmenuIndex, this.tag)
  }

  public menuVorauswahlEditor() {
    this.menuVorauswahlService.menuVorauswahlEditor(this.menu, this.tag, this.mandant)
  }

  public menuVorauswahlLoeschen() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {message: 'Sind Sie sicher, dass Sie das erstellte Menü löschen möchten?'},
    }).afterClosed().subscribe((result => {
      if (result) {
        this.aktuelleMenuVorauswahl = this.menuVorauswahlService.menuVorauswahl.getValue()
        for (let menu in this.aktuelleMenuVorauswahl[this.tag.datum].vorausgewaehlt.menues[this.menu.menueNr]){
          this.aktuelleMenuVorauswahl[this.tag.datum].vorausgewaehlt.menues[this.menu.menueNr][menu] = '0'
        }
        this.menuVorauswahlService.menuVorauswahl.next(this.aktuelleMenuVorauswahl)
        this.menuVorauswahlService.updateMenuVorauswahlSeitenleiste(
          this.mandant,
          null,
          null,
          null,
          null,
          this.aktuelleMenuVorauswahl[Object.keys(this.aktuelleMenuVorauswahl)[0]]
        )
      }
    }))
  }

  protected readonly environment = environment;
}
