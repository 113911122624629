<div id="bestellhistorie" *ngIf="zeitraum && api_zeitraum">
  <div class="bestellhistorie-date-picker">
    <div id="bestellhistorie_zeitraum">
      <strong>Aktueller Zeitraum</strong>
      <span>{{ zeitraum.von | datum }} - {{ zeitraum.bis | datum}}</span>
    </div>

    <div id="bestellhistorieKalender">
      <strong>Zeitraumauswahl</strong>
      <mat-calendar
        *ngIf="bestellung"
        (selectedChange)="selectedChange($event)"
        [dateClass]="markiereBestellungen()"
        [maxDate]="api_zeitraum.bis"
        [minDate]="api_zeitraum.von"
        [selected]="dateRange"
        startView="month"
        [headerComponent]="kalenderHeader">
      </mat-calendar>
    </div>
  </div>
  <div class="bestellhistorie-options">
    <button class="modus" (click)="aktuelle_woche()">Aktuelle Woche</button>
    <button class="modus" (click)="aktueller_monat()">Aktueller Monat</button>
  </div>
</div>
