<div id="rechtsKontent">
  <div id="rechtlich" *ngIf="mandant">
    <button
      (click)="flexLink.flexLinkPruefen('agb')"
      [class.active]="aktuelleUrl == 'agb'"
      *ngIf="flexLink.flexLinkTesten('agb')"
    >
      <mat-icon aria-label="AGB">fact_check</mat-icon>
      <span>AGB</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('datenschutz')"
      [class.active]="aktuelleUrl == 'datenschutz'"
      *ngIf="flexLink.flexLinkTesten('datenschutz')"
    >
      <mat-icon aria-label="Datenschutz">security</mat-icon>
      <span>Datenschutz</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('widerruf')"
      [class.active]="aktuelleUrl == 'widerruf'"
      *ngIf="flexLink.flexLinkTesten('widerruf')"
    >
      <mat-icon aria-label="Widerruf">pan_tool</mat-icon>
      <span>Widerruf</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('impressum')"
      [class.active]="aktuelleUrl == 'impressum'"
      *ngIf="flexLink.flexLinkTesten('impressum')"
    >
      <mat-icon aria-label="Impressum">gavel</mat-icon>
      <span>Impressum</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('kontakt')"
      [class.active]="aktuelleUrl == 'kontakt'"
      *ngIf="flexLink.flexLinkTesten('kontakt')"
    >
      <mat-icon aria-label="Kontakt">perm_phone_msg</mat-icon>
      <span>Kontakt</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('kuendigung')"
      [class.active]="aktuelleUrl == 'kuendigung'"
      *ngIf="mandant.mailerAktiv && flexLink.flexLinkTesten('kuendigung')"
    >
      <mat-icon aria-label="Kündigung">playlist_remove</mat-icon>
      <span>Kündigung</span>
    </button>
    <button
      (click)="flexLink.flexLinkPruefen('faq')"
      [class.active]="aktuelleUrl == 'faq'"
      *ngIf="flexLink.flexLinkTesten('faq')"
    >
      <mat-icon aria-label="FAQ">help</mat-icon>
      <span>FAQ</span>
    </button>
  </div>
  <div id="logo">
    <span>powered by</span>
    <a target="_blank" href="https://www.rcs.de">
      <img src="./assets/icons/company.webp">
    </a>
  </div>
</div>
