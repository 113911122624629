<form id="login-form" [formGroup]="login_form" (ngSubmit)="onSubmit()">
  <div class="login-data">
    <label for="benutzername">Login / Kundennummer</label>
    <input formControlName="login" id="benutzername">
  </div>
  <div class="login-data passwort">
    <label for="passwort">Passwort</label>
    <input formControlName="password" [type]="hide ? 'password' : 'text'" id="passwort">
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </div>
  <button mat-flat-button class="btn-login" [disabled]="!login_form.valid">Login</button>
  <div class="flex-container-row loginOptions">
    <span *ngIf="mandant && mandant.mailerAktiv" (click)="link_pw_forget(); buttonClicked()" id="passwortVergessen" class="link-pw-forget blue">Passwort vergessen?</span>
    <span
      *ngIf="mandant && mandant.darstellungRegistrierung != 3 && methode == 1"
      (click)="registrationService.link_to_registration(mandant); buttonClicked()"
      class="link-to-registration">Registrieren</span>
    <span
      *ngIf="mandant && mandant.darstellungRegistrierung != 3 && methode != 1 && mandant.mailerAktiv"
      (click)="registrationService.link_to_registration(mandant); buttonClicked()"
      class="link-to-registration">Registrieren</span>
  </div>
  <ng-container *ngIf="login_form.errors">
    <mat-card id="error" class="account-form-error shadow">
      {{ login_form.errors }}
    </mat-card>
  </ng-container>
</form>
