import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from './prozesse/dialog.service';
import { KundeService } from './kunden/kunde.service';
import { ApiCallService } from './api/api-call.service';
import { MandantenService } from './mandanten/mandanten.service';
import { MandantenAIZService } from './mandanten/mandanten-aiz.service';
import { HTTPInterceptorService } from './interceptors/response.interceptor';
import { RevisionService } from './api/revision.service';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MitteilungsService } from './prozesse/mitteilung.service';
import { BestellungService } from './bestellung/bestellung.service';
import { DateiService } from './prozesse/datei.service';
import { AuthGuardService } from './guards/auth-guard-serivce';
import { CordovaService } from './plattform/cordova.service';
import { StartupNavigationGuard } from './guards/startup-navigation.guard';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SpeiseplanMandantGuard } from './guards/speiseplan-mandant.guard';
import { StartupProfilGuard } from './guards/startup-profil.guard';
import { FormDatenService } from './prozesse/form-daten.service';
import { LoadingService } from './prozesse/loading.service';
import { LoadingScreenInterceptor } from './interceptors/loading.interceptor';
import { RegistrierungComponent } from './registrierung/registrierung.component';
import {WartungsabgleichService} from './prozesse/wartungsabgleich.service';
import {PlattformService} from './plattform/plattform.service';
import {ContentBlockService} from './prozesse/contentBlock.service';
import {ServiceWorkerService} from './navigation/service-worker.service';
import {StartupAppGuard} from './guards/startup-app.guard';
import {RouterService} from './navigation/router.service';
import {AppStateService} from './app/app-state.service';
import {MailWeiterleitungGuard} from './guards/mailWeiterleitung.guard';
import {MenueSyncroSopro} from './sopro/menue-syncro.sopro';
import {BenachrichtigungService} from './prozesse/benachrichtigung.service';
import {StateService} from './app/state.service';
import {SpeiseplanKalenderService} from '../submodul/obs.speiseplan/einzelbesteller/speiseplan-kalender/speiseplan-kalender.service';
import {LadeanzeigeService} from './prozesse/ladeanzeige.service';
import {
  BestellhistorieSeitenleisteService
} from '../submodul/obs.seitenleiste/components/bestellhistorie/bestellhistorieSeitenleistenService';
import {FarbkatalogService} from './farbkatalog.service';
import {GuthabenAufladenSopro} from './sopro/guthabenAufladen.sopro';
import {MenuVorauswahlServicesModule} from '../submodul/obs.menuVorauswahl/services/menuVorauswahlServices.module';
import {FlexLinkService} from './navigation/flexLink.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MenuVorauswahlServicesModule
  ],
  providers: [
    DialogService,
    MandantenService,
    MandantenAIZService,
    KundeService,
    ApiCallService,
    RevisionService,
    MitteilungsService,
    BestellungService,
    DateiService,
    AuthGuardService,
    CordovaService,
    StartupNavigationGuard,
    StartupProfilGuard,
    StartupAppGuard,
    MailWeiterleitungGuard,
    SpeiseplanMandantGuard,
    LoadingService,
    ContentBlockService,
    PlattformService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptorService, multi: true  },
	  FormDatenService,
    WartungsabgleichService,
    AppStateService,
    RouterService,
    MenueSyncroSopro,
    ServiceWorkerService,
    BenachrichtigungService,
    StateService,
    SpeiseplanKalenderService,
    LadeanzeigeService,
    BestellhistorieSeitenleisteService,
    FarbkatalogService,
    GuthabenAufladenSopro,
    FlexLinkService,
  ],
  declarations: [
    RegistrierungComponent,
  ]
})
export class ServicesModule { }
